export const thumbnailVenueEntry = {
  populate: {
    ...queryFragmentCollectionTypeDefault,
    cover_image: true,
    city_entry: {
      populate: {
        ...queryFragmentCollectionTypeDefault,
        cluster_entry: {
          populate: {
            ...queryFragmentCollectionTypeDefault,
          }
        }
      }
    }
  }
}
